<template>
  <div>
    <pcHeaders></pcHeaders>
    <div class="bannnn">
      <div class="w"
           v-if="msgInfo.infoMsg">
           <div style="font-size: 18px;text-align: center;">
            {{ msgInfo.infoMsg.TITLE }}
           </div>
           <div style="font-size: 16px;text-align: center;margin: 10px 0;">
            {{ msgInfo.infoMsg.LASTEDITTIME }} <span style="margin-left: 15px;">来源：{{msgInfo.infoMsg.PUBLISHFROM  }}</span>
           </div>
        <div v-if="msgInfo.infoMsg.INFO_CONTENT !== ''" v-html="msgInfo.infoMsg.INFO_CONTENT"
             style="min-height: 500px;border-bottom: 1px dashed #D6D6D6;font-size: 16px;">

        </div>
        <div v-else style="min-height: 500px;border-bottom: 1px dashed #D6D6D6;text-align: center;font-size: 18px;">
          暂无内容~
        </div>
        <div v-if="msgInfo.lasttTextMsg">

          <div class="next"
               @click="getList(msgInfo.lasttTextMsg.NAME)">
            上一篇：{{ msgInfo.lasttTextMsg.TITLE }}
          </div>
        </div>
        <div v-else>
          <div class="next">
            上一篇：没有上一篇了
          </div>
        </div>
        <div v-if="msgInfo.nextTextMsg">
          <div class="next"
               @click="getList(msgInfo.nextTextMsg.NAME)">
            下一篇：{{ msgInfo.nextTextMsg.TITLE }}
          </div>
        </div>
        <div v-else>
          <div class="next">
            下一篇：没有下一篇了
          </div>
        </div>

        <div style="display: flex;">
          <div style="display: flex;">
            <div></div>
            <div></div>
          </div>
          
        </div>
        <div class="examHead">
          <div><img style="margin-top: 5px"
                 src="../../assets/images/02_03.png"
                 alt=""></div>
          <div style="margin-left: 5px;">相关新闻推荐</div>
        </div>

        <div style="margin-top: 10px;">
          <div class="lst"
               @click="getList(item.NAME)"
               v-for="item in msgInfo.relatedInfoMessageList"
               :key="item.NAME">
            <div class="dian">

            </div>
            <div style="margin-left: 5px;">
              {{ item.TITLE }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <pcBottom></pcBottom>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import pcHeaders from '../component/head.vue';
import pcBottom from '../component/bottom.vue';
import { toGetMessage } from '@/api/index';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    pcHeaders,
    pcBottom
  },
  data() {
    // 这里存放数据
    return {
      msgInfo: {},
      codeNo:'',
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    '$route.query.name': function (newVal) {
      console.log('URL参数已经更新', newVal);
      this.codeNo = newVal;
      this.getList(this.codeNo);
    },
  },
  // 方法集合
  methods: {
    getList(id) {
      console.log(id);
      this.codeNo = id.toString()
      toGetMessage({ name: this.codeNo,codeNo:'1504' }).then(res => {
        this.msgInfo = res.data.data;
        window.scrollTo(0, 0);
      });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getList(this.$route.query.name);
    
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
.bannnn {
  width: 100%;
  display: flex;
  justify-content: center;
  .w {
    width: 1200px;
    padding: 40px 0;
    .next {
      cursor: pointer;
      font-size: 16px;
      margin-top: 10px;
    }
    .examHead {
      margin-top: 18px;
      display: flex;
      font-size: 20px;
      border-bottom: 1px dashed #d6d6d6;
      border-top: 1px dashed #d6d6d6;
      padding: 10px 0;
    }
    .lst {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-top: 5px;
      .dian {
        width: 5px;
        height: 5px;
        background: #4075c0;
        margin-right: 5px;
      }
    }
  }
}
</style>